import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import img2 from "../assets/Images2/Img2.JPG";
import img3 from "../assets/Images2/Img3.jpg";
import img4 from "../assets/Images2/Img4.jpg";
import img5 from "../assets/Images2/Img5.jpg";
import img6 from "../assets/Images2/Img6.jpg";
import img9 from "../assets/Images2/Img9.jpg";
import { motion } from "framer-motion";

const Section = styled.section`
  min-height: 100vh;
  position: relative;
  height: auto;

  margin: 0 auto;
  padding: 0;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  position: absolute;

  color: ${(props) => props.theme.text};
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  top: 1rem;
  left: 5%;
  z-index: 12;
  width: 100%;

  @media (max-width:  48em) {
    font-size: ${(props) => props.theme.fontxxl};
    
  }

  @media (max-width:  64em) {
    font-size: ${(props) => props.theme.fontxl};
    
  }
`;

const Left = styled.div`
  width: 40%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  min-height: 100vh;
  z-index: 11;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 300;
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width:  64em) {
    p{
      font-size: ${(props) => props.theme.fontmd};
    }
    
  }
  @media (max-width:  48em) {
    width: 40%;
    p{
      font-size: ${(props) => props.theme.fontsm};
    }
    
  }
  @media (max-width:  30em) {
    width: 40%;
    p{
      font-size: ${(props) => props.theme.fontxs};
    }
    
  }
`;

const Right = styled.div`
  position: absolute;
  left: 35%;
  min-height: 100vh;
  padding-left: 30%;
  background-color: ${(props) => props.theme.grey};
  /* width: 65%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;


  h1 {
    width: 5rem;
    margin: 0 2rem;
  }
`;

const Item = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  margin-right: 6rem;
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
    object-fit: cover;
  }

  h1 {
    display: inline-block;
    width: fit-content;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
  }

  @media (max-width:  48em) {
    width: 15rem;
    
  }
`;

const Story = ({ img, title = "" }) => {
  return (
    <Item
      initial={{ filter: "grayscale(100%)" }}
      whileInView={{ filter: "grayscale(0%)" }}
      transition={{ duration: 0.5 }}
      viewport={{ once: false, amount: "all" }}
    >
      <img src={img} alt={title} />
      <h1>{title}</h1>
    </Item>
  );
};

const Gallery = () => {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const horizontalRef = useRef(null);
  useLayoutEffect(() => {
    let element = ref.current;
    let scrollingElement = horizontalRef.current;
    let pinWrapWidth = scrollingElement.offsetWidth;
    let t1 = gsap.timeline();
    setTimeout(() => {
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: pinWrapWidth,
          scroller: ".App",
          scrub: true,
          pin: true,
        //   markers: true,
        },

        height: `${scrollingElement.scrollWidth}px`,
        ease: "none,",
      });

      //Horizontal Scroll
      t1.to(scrollingElement, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "top top",
          end: pinWrapWidth,
          scroller: ".App",
          scrub: true,
        //   markers: true,
        },

        x: -pinWrapWidth,
        ease: "none,",
      });

      ScrollTrigger.refresh();
    }, 1000);

    //Horizontol Scrolling

    return () => {
        t1.kill();
        ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="gallery">
      <Title data-scroll data-scroll-speed="-1">
        Gallery
      </Title>
      <Left>
        <p>
          As of now, my journey continues to evolve with newfound experiences
          and accomplishments. Recently, I have ventured into diverse artistic
          collaborations, exploring innovative avenues in acting and artistic
          expression.
          <br />
          <br />
          I've delved deeper into entrepreneurship, exploring novel ventures and
          honing my skills as a creator and visionary. Additionally, I've
          expanded my community engagement, leveraging my leadership abilities
          to initiate impactful projects. Continuously seeking knowledge,
          <br />
          <br />
          I've embraced new learning opportunities, broadening my expertise and
          perspectives. With each passing day, I am actively exploring uncharted
          territories, embracing change, and carving fresh paths toward personal
          and professional growth.
        </p>
      </Left>
      <Right ref={horizontalRef}>
        <Story img={img2} title="xyz" />
        <Story img={img3} title="xyz" />
        <Story img={img4} title="xyz" />
        <Story img={img5} title="xyz" />
        <Story img={img6} title="xyz" />
        <Story img={img9} title="xyz" />
        
      </Right>
    </Section>
  );
};

export default Gallery;
