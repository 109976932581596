import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import img12 from "../assets/Images2/Img12.jpg";
import img13 from "../assets/Images2/Img13.jpg";
import img14 from "../assets/Images2/Img14.jpg";
import img15 from "../assets/Images2/Img15.jpg";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef } from "react";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 25vw;
  height: auto;

  /* width: 65%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 64em) {
    width: 30vw;
  }
  @media (max-width: 48em) {
    width: 40vw;
  }
  @media (max-width: 30em) {
    width: 60vw;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;

  img {
    width: 100%;
    height: auto;
    z-index: 500;
  }
`;

const Story = ({ img, title }) => {
  return (
    <Item>
      <img src={img} alt={title} />
      <h2>{title}</h2>
    </Item>
  );
};

const Text = styled.div`
  width: 20%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: absolute;
  padding: 2rem;
  top: 0;
  right: 0;
  z-index: 11;

  @media (max-width: 48em) {
    display: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  height: 90vh;

  box-shadow: 0 0 0 4vw ${(props) => props.theme.text};
  border: 3px solid ${(props) => props.theme.body};
  z-index: 11;

  @media (max-width: 70em) {
    width: 40vw;
    height: 80vh;
  }
  @media (max-width: 64em) {
    width: 50vw;

    box-shadow: 0 0 0 60vw ${(props) => props.theme.text};
  }
  @media (max-width: 48em) {
    width: 60vw;
  }
  @media (max-width: 30em) {
    width: 80vw;
    height: 60vh;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  position: absolute;

  color: ${(props) => props.theme.body};
  text-shadow: 1px 1px 1px ${(props) => props.theme.text};
  top: 1rem;
  left: 5%;
  z-index: 12;
  width: 100%;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxl};
  }

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const NewArrivals = () => {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const ScrollingRef = useRef(null);

  useLayoutEffect(() => {
    let element = ref.current;
    let scrollingElement = ScrollingRef.current;

    let t1 = gsap.timeline();
    setTimeout(() => {
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: "bottom+=100% top-=100%",
          scroller: ".App",
          scrub: true,
          pin: true,
        },

        ease: "none,",
      });

      //Vertifcal Scroll
      t1.fromTo(
        scrollingElement,
        {
          y: "0",
        },
        {
          y: "-100%",
          scrollTrigger: {
            trigger: scrollingElement,
            start: "top top",
            end: "bottom top",
            scroller: ".App",
            scrub: true,
          },
        }
      );

      ScrollTrigger.refresh();
    }, 1000);

    //Horizontol Scrolling

    return () => {
      t1.kill();
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="new-arrival">
      <Overlay />
      <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      >
        New Arrival
      </Title>

      <Container ref={ScrollingRef}>
        <Story img={img12} title="xyz" />
        <Story img={img13} title="xyz" />
        <Story img={img14} title="xyz" />
        <Story img={img15} title="xyz" />
      </Container>

      <Text data-scroll data-scroll-speed="-4">
        I'm Vaishali Sachdeva, a dynamic fusion of artistry, leadership, and
        passion. As an Actor, Artist, and Miss South Asia NS, I thrive on
        creativity and evolution. My role at Victoria's Secret goes beyond work;
        it's where my energy and leadership shine, creating a sense of belonging
        in an inclusive space.
        <br />
        <br />
        At Browns Shoes Inc., commission-based sales challenge me to exceed
        targets, leaving lasting impressions. Acting at the Halifax Theatre for
        Young People amplified my love for the craft, nurturing collaboration
        and skill enhancement.
        <br />
        <br />
        Beyond, I engage in community leadership as a Street Team Member at
        Saint Mary's University. Pursuing a Bachelor of Arts in Psychology, I
        embody dedication, diverse talents, and an insatiable thirst for growth.
        I am Vaishali Sachdeva—an amalgamation of resilience, creativity, and
        unwavering curiosity.
      </Text>
    </Section>
  );
};

export default NewArrivals;
