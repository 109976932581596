import React, { useRef } from "react";
import styled from "styled-components";
import img1 from "../assets/Images2/Img7.jpg";
import img2 from "../assets/Images2/Img8.jpg";
import img3 from "../assets/Images2/Img1.jpg";

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  width: 80vw;
  display: flex;

  margin: 0 auto;

  @media (max-width: 48em) {
    width: 90vw;
  }

  @media (max-width: 30em) {
    width: 100vw;
  }
`;



const Title = styled.h1`
  font-size: ${(props) => props.theme.fontBig};
  font-family: "Kaushan Script";
  font-weight: 300;
  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 5;
  width: 100%;

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig}) -5vw`};
    top: 0;
    left: 0;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;

const Left = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: relative;
  z-index: 5;
  margin-top: 20%;

  @media (max-width: 64em) {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) !important;
    margin: 0 auto;
    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(1px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    width: 70%;
  }
`;

const Right = styled.div`
  width: 50%;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  .small-img-1 {
    width: 40%;
    position: absolute;
    right: 95%;
    bottom: 10%;
  }

  .small-img-2 {
    width: 40%;
    position: absolute;
    left: 80%;
    bottom: 30%;
  }

  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100vh;
      object-fit: cover;
    }

    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }

    .small-img-2 {
      width: 30%;
      height: auto;
      left: 60%;
      bottom: 20%;
    }
  }
`;

const About = () => {

  
  return (
    <Section id="fixed-target" className="about">
      
      <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      >
        About Me
      </Title>
      <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
        <p>
          I am a multifaceted individual, driven by a relentless pursuit of
          diverse passions. From acting, dancing, pageant and modeling to
          entrepreneurship, management, sales, and psychology, I have explored a
          wide spectrum of interests. 
          <br />
          <br />
          My philosophy is to seize every
          opportunity that comes my way and wholeheartedly commit to it. I am a
          firm believer in the power of learning and growth, which is why I
          eagerly embrace new experiences.
          <br />
          <br />
          Beyond, I engage in community leadership as a Street Team Member at
          Saint Mary's University. Pursuing a Bachelor of Arts in Psychology, I
          embody dedication, diverse talents, and an insatiable thirst for
          growth. I am Vaishali Sachdeva—an amalgamation of resilience,
          creativity, and unwavering curiosity.
        </p>
      </Left>
      <Right>
        <img src={img3} alt="About us" />
        <img
          data-scroll
          data-scroll-speed="5"
          src={img1}
          className="small-img-1"
          alt="About us"
        />
        <img
          data-scroll
          data-scroll-speed="-2"
          src={img2}
          className="small-img-2"
          alt="About us"
        />
      </Right>
    </Section>
  );
};

export default About;
