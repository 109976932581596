import {
    mobile,
    backend,
    creator,
    web,
    javascript,
    typescript,
    html,
    css,
    reactjs,
    redux,
    tailwind,
    nodejs,
    mongodb,
    git,
    figma,
    docker,
    starbucks,
    tesla,
    shopify,
    carrent,
    jobit,
    tripguide,
    threejs,
  } from "../assets";
  
  export const navLinks = [
    {
      id: "about",
      title: "About",
    },
    {
      id: "work",
      title: "Work",
    },
    {
      id: "contact",
      title: "Contact",
    },
  ];
  
  const services = [
    {
      title: "Web Developer",
      icon: web,
    },
    {
      title: "React Native Developer",
      icon: mobile,
    },
    {
      title: "Backend Developer",
      icon: backend,
    },
    {
      title: "Content Creator",
      icon: creator,
    },
  ];
  
  const technologies = [
    {
      name: "HTML 5",
      icon: html,
    },
    {
      name: "CSS 3",
      icon: css,
    },
    {
      name: "JavaScript",
      icon: javascript,
    },
    {
      name: "TypeScript",
      icon: typescript,
    },
    {
      name: "React JS",
      icon: reactjs,
    },
    {
      name: "Redux Toolkit",
      icon: redux,
    },
    {
      name: "Tailwind CSS",
      icon: tailwind,
    },
    {
      name: "Node JS",
      icon: nodejs,
    },
    {
      name: "MongoDB",
      icon: mongodb,
    },
    {
      name: "Three JS",
      icon: threejs,
    },
    {
      name: "git",
      icon: git,
    },
    {
      name: "figma",
      icon: figma,
    },
    {
      name: "docker",
      icon: docker,
    },
  ];
  
  const experiences = [
    {
      title: "Victoria's Secret",
      company_name: "Sales Associate",
      icon: starbucks,
      iconBg: "#383E56",
      tileBg: "#ffb3cb",
      date: "May 2022 - Present",
      points: [
        "ecognized and hired on the spot for enthusiastic and energetic approach",
        "Ensured exceptional customer service leading to increased customer satisfaction",
        "Actively contributed to sales growth and met sales targets consistently",
        "Embraced challenges and continuously learned in a diverse retail setting",
      ],
    },
    {
      title: "Browns Shoes Inc.",
      company_name: "Commission Sales Associate",
      icon: tesla,
      tileBg: "#CD7F32",
      iconBg: "#E6DEDD",
      date: "August 2022 - Present (1 year 6 months)",
      points: [
        "Excelled in meeting and exceeding commission-based sales targets",
        "Leveraged competitive environment to enhance business and collaboration skills",
        "Created positive and lasting impressions with customers, ensuring their satisfaction",
        "Implemented strategies to effectively promote products and drive sales",
      ],
    },
    {
      title: "Halifax Theatre for Young People",
      company_name: "Professional Actor",
      icon: shopify,
      tileBg: "#F28C28",
      iconBg: "#383E56",
      date: "March 2023 - April 2023 (2 months)",
      points: [
        "Collaborated effectively with the entire team, fostering a great working environment",
        "Challenging yet fulfilling experience, fueled by passion for acting",
        "Demonstrated utmost dedication, going above and beyond in performance",
        "Significantly enhanced acting skills and unleashed creativity through the role",
      ],
    },
  ];

  const education = [
    {
      title: "The Emerald Heights International School, Indore",
      company_name: "High School Diploma",
      icon: starbucks,
      tileBg: "#1d1836",
      iconBg: "#383E56",
      date: "(April 2014 - March 2020)",
      points: [
        "Successfully completed a rigorous high school curriculum in History and political science including elective coursework",
        "Engaged in extracurricular activities to broaden knowledge and experiences outside the classroom",
        "Actively involved in the National Cadet Corps (NCC), demonstrating leadership and teamwork skills",
        "Participated in various clubs or societies fostering personal interests and developing diverse skills",
      ],
    },
    {
      title: "Saint Mary's University",
      company_name: "Bachelor of Arts - BA, Psychology",
      icon: tesla,
      iconBg: "#E6DEDD",
      tileBg: "#D80024",
      date: "January 2022 - December 2025",
      points: [
        "Developing and maintaining web applications using React.js and other related technologies.",
        "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
        "Implementing responsive design and ensuring cross-browser compatibility.",
        "Participating in code reviews and providing constructive feedback to other developers.",
      ],
    },
  ];
  
  const testimonials = [
    {
      testimonial:
        "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
      name: "Sara Lee",
      designation: "CFO",
      company: "Acme Co",
      image: "https://randomuser.me/api/portraits/women/4.jpg",
    },
    {
      testimonial:
        "I've never met a web developer who truly cares about their clients' success like Rick does.",
      name: "Chris Brown",
      designation: "COO",
      company: "DEF Corp",
      image: "https://randomuser.me/api/portraits/men/5.jpg",
    },
    {
      testimonial:
        "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
      name: "Lisa Wang",
      designation: "CTO",
      company: "456 Enterprises",
      image: "https://randomuser.me/api/portraits/women/6.jpg",
    },
  ];
  
  const projects = [
    {
      name: "Car Rent",
      description:
        "Web-based platform that allows users to search, book, and manage car rentals from various providers, providing a convenient and efficient solution for transportation needs.",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "mongodb",
          color: "green-text-gradient",
        },
        {
          name: "tailwind",
          color: "pink-text-gradient",
        },
      ],
      image: carrent,
      source_code_link: "https://github.com/",
    },
    {
      name: "Job IT",
      description:
        "Web application that enables users to search for job openings, view estimated salary ranges for positions, and locate available jobs based on their current location.",
      tags: [
        {
          name: "react",
          color: "blue-text-gradient",
        },
        {
          name: "restapi",
          color: "green-text-gradient",
        },
        {
          name: "scss",
          color: "pink-text-gradient",
        },
      ],
      image: jobit,
      source_code_link: "https://github.com/",
    },
    {
      name: "Trip Guide",
      description:
        "A comprehensive travel booking platform that allows users to book flights, hotels, and rental cars, and offers curated recommendations for popular destinations.",
      tags: [
        {
          name: "nextjs",
          color: "blue-text-gradient",
        },
        {
          name: "supabase",
          color: "green-text-gradient",
        },
        {
          name: "css",
          color: "pink-text-gradient",
        },
      ],
      image: tripguide,
      source_code_link: "https://github.com/",
    },
  ];
  
  export { services, technologies, experiences, testimonials, projects, education };