import React, { useState, useRef } from "react";
import styled from "styled-components";
import linkedin from "../assets/Images/linkedin.png";
import instagram from "../assets/Images/instagram.png";
import gmail from "../assets/Images/gmail.png";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { slideIn } from "../utils/motion";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  position: relative;
  overflow-x: hidden;

`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: 10vw;
    height: auto;
  }

  h3 {
    font-size: ${(props) => props.theme.fontxxl};
    font-family: "Kaushan Script";

    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontxl};
    }
  }
`;

const FooterComponent = styled(motion.div)`
  width: 80vw;

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    margin-top: 4rem;
    padding: 0 1rem;
    border-top: 1px solid ${(props) => props.theme.text};
    border-bottom: 1px solid ${(props) => props.theme.text};

    @media (max-width: 48em) {
      justify-content: center;
    }
  }

  li {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontlg};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }

    @media (max-width: 64em) {
      padding: 1rem;
      font-size: ${(props) => props.theme.fontmd};
    }
  }

  li:nth-last-child(-n + 3) {
   
    padding: 2rem 1rem;
  }

  img {
    height: 2rem; 
    width: 2rem; 
  }
`;

const Bottom = styled.div`
  padding: 0.5rem 0;
  margin: 0 4 rem;
  font-size: ${(props) => props.theme.fontlg};

  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: underline;
  }

  @media (max-width: 64em) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    span {
      transform: none !important;
    }
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const ContactDiv = styled(motion.div)`
  /* Extra large screens (xl) */
  height: 20%;
  width: 50%;
  

  @media (max-width: 64em) {
    justify-content: center;
  }

  /* For all screen sizes */
  .container {
    flex-direction: column-reverse; 
    display: flex;
    gap: 0.5rem; 
    overflow-x: hidden;
  }
`;

const ContactForm = styled(motion.div)`
  flex: 0.75; 
  background-color: #000; 
  padding: 2rem; 
  border-radius: 1rem;

  p {
    font-size: ${(props) => props.theme.fontlg}; 
    text-transform: uppercase; 
    letter-spacing: 0.05em; 
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  /* @media (min-width: 640px) {
    p {
      font-size: 18px; 
    }
  } */

  h3 {
    color: #fff; 
    font-weight: 900; 
    font-size: 30px; 

    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontmd};
    }
    @media (max-width: 30em) {
      font-size: ${(props) => props.theme.fontsm};
      width: 70%;
    }
    
    /* @media (min-width: 640px) {
      font-size: 50px; 
    }
    @media (min-width: 768px) {
      font-size: 60px; 
    }
    @media (max-width: 639px) {
      font-size: 40px; 
    } */
  }

   form {
    display: flex; 
    flex-direction: column; 
  } 
  
 

  label {
    display: flex;
    flex-direction: column;
  } 

  input {
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem; 
    padding-left: 0.5rem; 
    padding-right: 0.5rem;
    background-color: #1e1d1ddb;
    border-radius: 0.375rem; 
    outline: none;
    border: none;
    font-weight: 500;
    color: #fff;
  } 

  

  input::placeholder {
    color: #fff;
  }


  textarea {
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem; 
    padding-left: 0.5rem; 
    padding-right: 0.5rem;
    background-color: #1e1d1ddb;
    border-radius: 0.375rem; 
    outline: none;
    border: none;
    font-weight: 500;
    color: #fff;
  }

  textarea::placeholder {
    color: #fff;
  }

  button {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    outline: none;
    background-color: #1e1d1ddb;
    width: fit-content;
    color: white;
    font-weight: 700;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 6px rgba(39, 124, 253, 0.1);
    cursor: pointer;
  }
`;

const Footer = () => {
  const { scroll } = useLocomotiveScroll();
  const handleScroll = (id) => {
    let elem = document.querySelector(id);
    scroll.scrollTo(elem, {
      offset: "-100",
      duration: "2000",
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_svfabx9",
        "template_0zfilqr",
        {
          from_name: form.name,
          to_name: "Vaishali",
          from_email: form.email,
          to_email: "vs.punjabi1504@gmail.com",
          message: form.message,
        },
        "c2YScUUcdQFx8L5t0"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible");

          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.log(error);
          alert("Something went wrong");
        }
      );
  };

  return (
    <Section>
      <ContactDiv>
        <ContactForm variants={slideIn("left", "tween", 0.2, 1)}>
          <p>Get in Touch</p>
          <h3> Contact. </h3>

          <form action="" ref={formRef} onSubmit={handleSubmit}>
            <label>
              <span> Your Name</span>
            </label>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="What's your name"
            />
            <label>
              <span> Your Email</span>
            </label>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="What's your email"
            />
            <label>
              <span> Your Message</span>
            </label>
            <textarea
              t
              rows="7"
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="What do you want to change"
            />
            <button type="submit">{loading ? "Sending.." : "Send"}</button>
          </form>
        </ContactForm>
      </ContactDiv>
      <LogoContainer>
        <h3 data-Scroll data-scroll-speed="-1">
          Vaishali Sachdeva
        </h3>
      </LogoContainer>
      <FooterComponent
        initial={{ y: "-400px" }}
        whileInView={{ y: "0" }}
        viewport={{ once: false }}
        transition={{
          duration: 1.5,
        }}
      >
        <ul>
          <li onClick={() => handleScroll("#home")}>home</li>
          <li onClick={() => handleScroll(".about")}>about</li>
          <li onClick={() => handleScroll("#gallery")}>gallery</li>
          <li onClick={() => handleScroll("#new-arrival")}>new arrival</li>
          <li>
            <a
              href="https://www.linkedin.com/in/vaishali-sachdeva-5800a2188/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="" />
            </a>
          </li>
          <li>
            <a
              href="mailto:vaishalisachdeva15@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={gmail} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/vaishali__sachdeva/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="" />
            </a>
          </li>
        </ul>
        <Bottom
          data-Scroll
          data-scroll-speed="2"
          data-scroll-direction="horizontal"
        >
          <span>&copy; {new Date().getFullYear()} All Rights Reserved</span>
          <span>Made with &hearts; by VS </span>
        </Bottom>
      </FooterComponent>
    </Section>
  );
};

export default Footer;
