import { createGlobalStyle } from "styled-components";
import "@fontsource/kaushan-script";
import "@fontsource/sirin-stencil";

const GlobalStyles = createGlobalStyle`

*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body {
    width: 100vw;
    font-family: Sirin Stencil;
    overflow-x: hidden;
    
}

h1,h2,h3,h4,h5,h6{
    margin:0;
    padding: 0;
}

a{
    color:inherit;
    text-decoration:none;
}

.vertical-timeline-custom-style .vertical-timeline-element-date,
.vertical-timeline-custom-style .vertical-timeline-element-title {
  color: black;
}
`;

export default GlobalStyles;
