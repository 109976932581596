import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import "react-vertical-timeline-component/style.min.css";
import { textVariant } from "../utils/motion";

import styled from "styled-components";
import { education } from "../constants/constant";

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  background-color: ${(props) => props.theme.text};
`;

const ClippedBottom = styled.div`
  width: 100%;
  height: 50px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  background: linear-gradient(${(props) => props.theme.body}, transparent);
  z-index: 1;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  position: absolute;

  color: ${(props) => props.theme.body};
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  top: 1rem;
  left: 5%;
  z-index: 12;
  width: 100%;
  margin-bottom: 200px;
`;

const ExperienceTitle = styled(motion.span)`
  font-size: ${(props) => props.theme.fontlg};
  font-family: "Kaushan Script";
  font-weight: 300;
  position: absolute;

  color: ${(props) => props.theme.text};
  
  top: 1rem;
  left: 5%;
  z-index: 12;
  width: 100%;
`;

const StyledExperience = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.text};
  /* Add more custom CSS properties here */

  img{
    object-fit: contain;
  }
`;

const WorkComponent = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const Styledli= styled(motion.li)`
/* CSS equivalent of Tailwind classes */
.text-white-100 {
  color: #ffffff; /* White color */
}

.text-14px {
  font-size: 14px; /* Font size of 14 pixels */
}

.padding-left-1 {
  padding-left: 0.25rem; /* 1px * 0.25 */
}

.tracking-wider {
  letter-spacing: 0.05em; /* Additional letter spacing */
}


`


const ExperienceCard = ({ experience }) => (
    <VerticalTimelineElement
      className="vertical-timeline-custom-style" // Add a custom class for targeting styles
      contentStyle={{ background: experience.tileBg,font:'kausahn-script', color: "#fff"}}
      contentArrowStyle={{
        borderRight: "7px solid #232631",
      }}
      date={experience.date}
      iconStyle={{ background: experience.iconBg }}
      icon={<WorkComponent><img src={experience.icon} alt={experience.company_name} width="60%" height="60%" /></WorkComponent>}
    >
      <div>
        <ExperienceTitle>{experience.title}</ExperienceTitle>
        <p>
            {experience.company_name}
        </p>
      </div>

      <styleUL>
        {experience.points.map((point, index) =>(
            <Styledli
                key={`experience-point-${index}`}
            >
                {point}

            </Styledli>
        ))}

      </styleUL>
    </VerticalTimelineElement>
  );
  
  const Education = () => {
    return (
      <Section>
        <WorkComponent variants={textVariant()}>
          <Title data-scroll data-scroll-speed="-1">
            Education
          </Title>
        </WorkComponent>
        <StyledExperience>
          <VerticalTimeline>
            {education.map((education, index) => (
              <ExperienceCard key={index} experience={education} />
            ))}
          </VerticalTimeline>
        </StyledExperience>
        <ClippedBottom />
      </Section>
    );
  };
  
  export default Education